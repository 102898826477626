import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Button, Card, PdfViewer, Typography } from '@otcmarketsgroup/otcm-ui-lib';

const PreviewSection = ({ previewData, pdfFile, title, note, disable, onEdit, onSubmit, submitErrorMsg }) => {
    return (
        <>
            <Card>
                <Grid container spacing={5}>
                    <Grid size={7}>
                        <Typography variant='subtitle1' sx={{ mb: 2 }}>
                            {title}
                        </Typography>
                        <Card border>
                            <Grid container columnSpacing={5} rowSpacing={1}>
                                {previewData.map(data => {
                                    const displayValue = typeof data.value === 'function' ? data.value() : data.value;

                                    return <React.Fragment key={data.label}>
                                        <Grid size={6}>
                                            <Typography variant='subtitle2'>
                                                {data.label}
                                            </Typography>
                                        </Grid>
                                        <Grid size={6}>
                                            {data.value ? <Typography variant='subtitle1' sx={{ wordBreak: "break-word" }}>
                                                {displayValue}
                                            </Typography> : <Typography variant='subtitle2'><i>Empty</i></Typography>}
                                        </Grid>
                                    </React.Fragment>;
                                })}
                            </Grid>
                        </Card>
                        <Typography variant='subtitle2' sx={{ mt: 2 }}>
                            {note}
                        </Typography>
                    </Grid>
                    <Grid size={5}>
                        <PdfViewer pdf={pdfFile} />
                    </Grid>
                </Grid>
            </Card>
            {submitErrorMsg && <Typography variant='subtitle1' color='warning' sx={{ mt: 2 }}>
                {submitErrorMsg}
            </Typography>}
            <Box sx={{ textAlign: 'right', my: 3 }}>
                <Button disabled={disable} onClick={onEdit} variant='outlined' startIcon={<FontAwesomeIcon icon={['fas', 'chevron-left']} />}>
                    Edit
                </Button>
                <Button isLoading={disable} onClick={onSubmit} gutterLeft>
                    Submit
                </Button>
            </Box>
        </>
    );
};

PreviewSection.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    note: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    previewData: PropTypes.array,
    onEdit: PropTypes.func,
    onSubmit: PropTypes.func,
    pdfFile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disable: PropTypes.bool,
    submitErrorMsg: PropTypes.string
};

export default PreviewSection;