import { useCallback } from 'react';

const useRouteReplacement = (route) => {
    const replacePlaceholders = useCallback((replacements) => {
        return Object.entries(replacements).reduce((acc, [key, value]) => {
            return acc.replace(key, value);
        }, route);
    }, [route]);

    return replacePlaceholders;
};

export default useRouteReplacement;
