import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OtcThemeProvider, OTCHeader, OTCFooter } from '@otcmarketsgroup/otcm-ui-lib';
import { withRouter } from '../../components/WithRouter';
import { useCompanyData } from '../../hooks/useCompanyData';
import { useAuthContext } from '../../context/Auth.context';
import { useCompanyContext } from '../../context/Company.context';
import CompanyHeader from '../../components/CompanyHeader';
import NotFound from '../../components/NotFound';
import styles from './DnsLayout.module.scss';

const DnsLayout = ({ params, children, location, navigate }) => {
  const [authState] = useAuthContext();
  const [companyState] = useCompanyContext();
  const { symbol } = params;
  const { isAuthenticated, invalidSymbol, user } = authState;
  const { profile, inside } = companyState;

  useCompanyData(symbol);

  useEffect(() => {
    if (isAuthenticated) {
      const isAddFinancial = location.pathname.includes('/add-financial-report');
      let pathname = location.pathname;
      if (!user.author && isAddFinancial) {
        pathname = pathname.replace('/add-financial-report', '');
      }
      navigate({ ...location, search: '', pathname });
    }
  }, [isAuthenticated]);

  return (
    <OtcThemeProvider>
      {isAuthenticated && (
        <div className={styles.container}>
          <OTCHeader logoSrc='/images/OTC_IQ_Logo.png' />
          <main>
            <CompanyHeader className='mbXL' symbol={symbol} profile={profile} inside={inside} />
            {invalidSymbol ? <NotFound /> : children}
          </main>
          <OTCFooter />
        </div>
      )}
    </OtcThemeProvider>
  );
};

DnsLayout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  params: PropTypes.object,
  navigate: PropTypes.func
};

export default withRouter(DnsLayout);
