import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSecFilings } from '../../api/company';
import { Box } from '@mui/material';
import { Button, Card, CardHeader, CardTitle, CardContent, Pagination, Table } from '@otcmarketsgroup/otcm-ui-lib';
import { format } from '../../utlis/locale';
import { OTC_BASE_URL } from '../../constants';
import { getGUID } from '../../utlis/helper';
import ViewCell from './ViewCell';

const SecFilingTable = ({ symbol }) => {
    const defaultFilings = { error: false, isLoaded: false, isLoading: false, data: null, params: { symbol, pageSize: 5 }, sorting: { key: 'receivedDate', direction: 'DESC' } };
    const [filingsObj, setFilings] = useState(defaultFilings);
    const hasFilings = !!filingsObj?.data?.records.length > 0;
    const showPagination = hasFilings && filingsObj?.data?.pages > 1;

    useEffect(() => {
        const initialParams = {
            ...filingsObj.params,
            symbol
        };

        loadSecFilings(initialParams);
    }, [symbol]);

    const filingsColumns = [
        { id: 'receivedDate', header: 'Received', sortable: true, renderCell: item => format(item.receivedDate, item.receivedDate, 'date') },
        { id: 'formType', header: 'Form Type', sortable: true, renderCell: item => renderFormType(item) },
        { id: 'periodEndDate', header: 'Period End Date', sortable: true, renderCell: item => format(item.periodEndDate, item.periodEndDate, 'date') },
        {
            id: '', header: 'Report', renderCell: item => {
                return item.paperOnly ? <i>Paper Only</i> : <ViewCell item={item} />;
            }
        }
    ];

    const renderFormType = (data) => {
        return <a href={`${OTC_BASE_URL}/filing/html?id=${data.id}&guid=${getGUID(data.guid)}`} target='_blank' rel='noopener'>
            <Button variant='text' gutterRight>
                {data.formType}
            </Button>
        </a>;
    };

    const loadSecFilings = filingsParams => {
        setFilings({ ...filingsObj, isLoading: true });
        getSecFilings(filingsParams)
            .then(data => {
                setFilings({
                    ...filingsObj,
                    isLoading: false,
                    isLoaded: true,
                    data: data,
                    params: {
                        symbol,
                        page: data.currentPage,
                        pageSize: 5,
                        sortOn: data.sortOn,
                        sortDir: data.sortDir
                    },
                    sorting: {
                        key: data.sortOn,
                        direction: data.sortDir
                    }
                });
            })
            .catch(error => {
                setFilings({
                    ...filingsObj,
                    isLoading: false,
                    isLoaded: true,
                    error: error?.response?.data?.message || 'Failed to retrieve data.'
                });
            });
    };

    const handlePageClick = (e, page) => {
        const params = {
            ...filingsObj.params,
            page
        };
        
        loadSecFilings(params);
    };


    const handleSort = (columnId, direction) => {
        const params = {
            ...filingsObj.params,
            page: 1,
            sortDir: direction,
            sortOn: columnId
        };

        setFilings({
            ...filingsObj,
            params: {
                ...filingsObj.params,
                page: 1,
                sortDir: direction,
                sortOn: columnId
            }
        });

        loadSecFilings(params);
    };

    return <Card>
        <CardHeader>
            <CardTitle title='SEC Filings' icon={<FontAwesomeIcon icon={['far', 'folder']} />} />
        </CardHeader>
        <CardContent>
            <Table
                isLoading={filingsObj.isLoading}
                errorText={filingsObj.error}
                columns={filingsColumns}
                data={filingsObj?.data?.records || []}
                sortConfig={filingsObj?.sorting}
                onSort={handleSort}
            />
            {showPagination && <Box display='flex' justifyContent='flex-end' pt={2}>
                <Pagination disabled={filingsObj.isLoading} page={filingsObj?.data?.currentPage} count={filingsObj?.data?.pages} onPageChange={handlePageClick} />
            </Box>}
        </CardContent>
    </Card>;
};

SecFilingTable.propTypes = {
    symbol: PropTypes.string
};

export default SecFilingTable;
