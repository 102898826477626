import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const CompanyContext = createContext();

export const CompanyConsumer = CompanyContext.Consumer;

const initialState = {
  profile: null,
  inside: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_COMPANY':
      return {
        ...state,
        profile: action.payload
      };

    case 'SET_COMPANY_INSIDE':
      return {
        ...state,
        inside: action.payload
      };
    default:
      throw new Error();
  }
};

export const CompanyProvider = props => {
  const [companyState, companyDispatch] = useReducer(reducer, initialState);

  return (
    <CompanyContext.Provider value={[companyState, companyDispatch]}>
      {props.children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => {
  return useContext(CompanyContext);
};

CompanyProvider.propTypes = {
  children: PropTypes.node.isRequired
};
