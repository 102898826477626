const getConfig = () => {
  if (window.location.host.match(/localhost/)) {
    return {
      REACT_ENV: 'local',
      OTC_URL: 'https://otcm-web.test.otcmkt.com',
      GTM_ID: process.env.REACT_APP_GTM_ID
    };
  } else if (window.location.host.match(/dev/)) {
    return {
      REACT_ENV: 'dev',
      OTC_URL: 'https://otcm-web.dev.otcmkt.com',
      GTM_ID: 'GTM-KFC6DL8P'
    };
  } else if (window.location.host.match(/test/)) {
    return {
      REACT_ENV: 'test',
      OTC_URL: 'https://otcm-web.test.otcmkt.com',
      GTM_ID: 'GTM-KFC6DL8P'
    };
  } else if (window.location.host.match(/stage/)) {
    return {
      REACT_ENV: 'stage',
      OTC_URL: 'https://otcmarkets.stage.otcmkt.com',
      GTM_ID: 'GTM-P4XC3F2P'
    };
  } else if (window.location.host.match(/.otcmarkets\.com/) ||
  window.location.host.match(/www\.otcmarkets\.com/) || window.location.host.match(/prod/)) {
    return {
      REACT_ENV: 'prod',
      OTC_URL: 'https://www.otcmarkets.com',
      GTM_ID: 'GTM-MC9G6SK5'
    };
  }
};

export default getConfig;
