import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Nunito Sans',
  fonts: [
    { src: '/fonts/Nunito_Sans/NunitoSans-ExtraLight.woff', fontWeight: 200 },
    { src: '/fonts/Nunito_Sans/NunitoSans-ExtraLightItalic.woff', fontWeight: 200, fontStyle: 'italic' },
    { src: '/fonts/Nunito_Sans/NunitoSans-Regular.woff', fontWeight: 400 },
    { src: '/fonts/Nunito_Sans/NunitoSans-SemiBold.woff', fontWeight: 600 },
    { src: '/fonts/Nunito_Sans/NunitoSans-Bold.woff', fontWeight: 700 }
  ]
});

export const styles = StyleSheet.create({
  document: {
    position: 'relative',
    width: '100%',
    backgroundColor: 'white',
    fontFamily: 'Nunito Sans'
  },
  page: {
    position: 'relative',
    padding: 32
  },
  header: {
    fontSize: '24px',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '24px'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerText: {
    display: 'flex',
    fontSize: 12,
    left: 0,
    bottom: 32,
    right: 0,
    fontWeight: 200,
    fontStyle: 'italic',
    textAlign: 'left',
    padding: '0 50px',
    fontSize: 14,
    color: '#000000'
  },
  list: {
    display: 'block',
    marginLeft: 10,
    marginBottom: 10,
  },
  listItem: {
    marginBottom: 5,
    fontSize: 12,
    fontWeight: 200
  },
});

export default styles;
