import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useRouteReplacement from '../../hooks/useRouteReplacement';
import { AuthContext } from '../../context/Auth.context';
import { CompanyContext } from '../../context/Company.context';
import { DisclosureContext } from '../../context/Disclosure.context';
import SecFilingTable from '../../container/SecFilingTable';
import { withRouter } from '../../components/WithRouter';
import { FINANCIAL_FORM_FIELDS, STATUS_MAP } from '../../constants';
import { reportTypeTg32, disableEditReportTypes } from '../../constants/reportTypes';
import { ROUTES } from '../../constants/routes';
import { format } from '../../utlis/locale';
import { getFilings, updateFiling, deleteFiling } from '../../api/filing';
import ActionDialog from './ActionDialog';
import { Button, Card, CardHeader, CardTitle, CardContent, DropdownMenu, Pagination, Table, SelectField } from '@otcmarketsgroup/otcm-ui-lib';

const defaultFilingParams = {
  pageSize: 5
};

const DisclosurePage = ({ params, navigate }) => {
  const [authState] = useContext(AuthContext);
  const [companyState] = useContext(CompanyContext);
  const [disclosureState] = useContext(DisclosureContext);
  const [filingList, setFilingList] = useState(null);
  const [isFilingLoading, setFilingLoading] = useState(false);
  const [filingParams, setFilingParams] = useState(defaultFilingParams);
  const [errors, setErrors] = useState({ filingList: null, deleteItem: null, publishItem: null });
  const [deleteItem, setDeleteItem] = useState({ show: false, data: null, isDeleting: false, isDeleted: false });
  const [publishItem, setPublishItem] = useState({ show: false, data: null, isPublishing: false, isPublished: false });
  const amendReplacetRoute = useRouteReplacement(ROUTES.amendFinancialReport);
  const editReplaceRoute = useRouteReplacement(ROUTES.editFinancialReport);
  const statusOptions = disclosureState.statusOptions;
  const symbol = params?.symbol;
  const company = companyState.profile;
  const user = authState.user;
  const isAuthor = user.author;
  const isAdmin = user.isAdmin;
  const compId = company?.id;
  const hasFilings = !!filingList?.records.length > 0;
  const showPagination = hasFilings && filingList?.pages > 1;
  const deleteDialogContent = deleteItem.isDeleted ? `Your ${deleteItem?.data?.reportType} has successfully been deleted.` : `Are you sure you want to delete ${deleteItem?.data?.reportType}${deleteItem?.data?.title ? ' - ' + deleteItem?.data?.title : ''} (Period End Date ${format(deleteItem?.data?.periodDate, deleteItem?.data?.periodDate, 'date')} - Published ${format(deleteItem?.data?.releaseDate, deleteItem?.data?.releaseDate, 'dateTime')} ET)?`;
  const publishDialogContent = publishItem.isPublished ? `Your ${publishItem?.data?.reportType} has successfully been published.` : `Are you sure you want to Publish ${publishItem?.data?.reportType}${publishItem?.data?.title ? ' - ' + publishItem?.data?.title : ''} (Period End Date ${format(publishItem?.data?.periodDate, publishItem?.data?.periodDate, 'date')})`;

  useEffect(() => {
    if (compId) {
      loadFilingData(filingParams);
    }
  }, [compId]);

  useEffect(() => {
    if (!deleteItem.show) setDeleteItem({ ...deleteItem, show: false, isDeleting: false, isDeleted: false });
  }, [deleteItem.show]);

  const loadFilingData = (params) => {
    setFilingLoading(true);
    setFilingParams(params);
    setErrors({
      ...errors,
      filingList: false
    });

    getFilings(compId, params)
      .then(data => {
        setFilingList(data);
        setFilingLoading(false);
      })
      .catch(error => {
        setFilingList(null);
        setFilingLoading(false);
        setErrors({
          ...errors,
          filingList: error?.response?.data?.message || 'Failed to retrieve data.'
        });
      });
  };

  const filingsColumns = [
    { id: 'releaseDate', header: 'Published', renderCell: item => renderDateTime(item.releaseDate) },
    { id: 'title', header: 'Report Type', renderCell: item => renderTitle(item) },
    { id: 'periodDate', header: 'Period End Date', renderCell: item => format(item.periodDate, item.periodDate, 'date'), width: '150px' },
    ...(isAuthor ? [
      { id: 'statusId', header: 'Status', renderCell: item => STATUS_MAP[item.statusId], width: '100px' },
      { id: '', header: 'Created', renderCell: item => renderCreated(item) },
      { id: 'lastModifiedDate', header: 'Last Modified', renderCell: item => renderDateTime(item.lastModifiedDate) },
      { id: '', header: 'Action', renderCell: item => renderActions(item), width: '100px' }
    ] : []),
  ];

  const renderDateTime = datetime => {
    const date = <Box component='span'>{format(datetime, datetime, 'date')}</Box>;
    const time = <Box component='span' display='inline-block'>{format(datetime, datetime, 'time') + ' ET'}</Box>;

    let dateString = date;

    if (isAuthor) {
      dateString = <Box>
        {date} {time}
      </Box>;
    }
    
    return dateString;
  };
  
  const renderTitle = (data) => {
    // hide links for pending
    const formatTitle = data.isAmendment && data.title ===  ' - A' ? 'A' :  data.title ;
    const reportTitle = `${data.reportType}${formatTitle ? ' - ' + formatTitle : ''}`;
    const reportPending = data.statusId === 'S';
    return <>
      {!reportPending && <a href={`/filing/content?symbol=${symbol}&id=${data.id}`} target='_blank' rel='noopener'>
        <Button variant='text'>{reportTitle}</Button>
      </a>}
      {reportPending && <span>{reportTitle}</span>}
    </>;
  };

  const renderCreated = (data) => {
    const datetime = renderDateTime(data.createdDate);
    const author = !data?.createdByUser ? null : `${data.createdByUser?.firstName} ${data.createdByUser?.lastName}`;
    const showAuthor = !!author;

    return <>
      {datetime}
      {showAuthor && author}
    </>;
  };

  const handleDeleteClick = reportData => {
    setDeleteItem({
      ...deleteItem,
      show: true,
      data: reportData
    });
  };

  const renderActions = (reportData) => {
    const id = reportData.id;
    const status = reportData.statusId;
    const isAmendment = reportData.isAmendment;
    const isTG32 = reportData.typeId === reportTypeTg32;
    const noActionList = ['U', 'D', 'S', 'B'];
    const noActions = noActionList.includes(status);

    if (noActions) return null;

    if (disableEditReportTypes.includes(reportData.typeId)) {
      if ((isAdmin && (status === 'A' || status === 'I')) || status === 'P') return <DropdownMenu buttonText='Select' endIcon={<FontAwesomeIcon icon={['fas', 'chevron-down']} />} menuItems={[
        {
          label: 'Delete', onClick: () => {
            handleDeleteClick(reportData);
          }
        }
      ]} />;

      return null;
    }

    const amendRoute = amendReplacetRoute({
      ':symbol': symbol,
      ':amendReportId': id,
    });

    const editRoute = editReplaceRoute({
      ':symbol': symbol,
      ':reportId': id,
    });

    if (!isAdmin && status === 'A' && !isTG32) return <Link to={amendRoute}>
      <Button variant='text'>
        Amend
      </Button>
    </Link>;

    if (isAdmin && status === 'I') return <Button variant='text' onClick={_ => handleDeleteClick(reportData)}>
      Delete
    </Button>;

    if (isAdmin && status === 'A' && !isTG32) return <DropdownMenu buttonText='Select' endIcon={<FontAwesomeIcon icon={['fas', 'chevron-down']} />} menuItems={[
      {
        label: 'Amend', onClick: () => navigate(ROUTES.amendFinancialReport.replace(':symbol', symbol).replace(':amendReportId', reportData.id))
      },
      ...(!isAmendment ? [
        {
          label: 'Edit', onClick: () => {
            navigate(editRoute);
          }
        }
      ] : []),
      {
        label: 'Delete', onClick: () => {
          handleDeleteClick(reportData);
        }
      }
    ]} />;

    if (isAdmin && status === 'A' && isTG32) return <DropdownMenu buttonText='Select' endIcon={<FontAwesomeIcon icon={['fas', 'chevron-down']} />} menuItems={[
      {
        label: 'Delete', onClick: () => {
          handleDeleteClick(reportData);
        }
      }
    ]} />;

    if (status === 'P') return <DropdownMenu buttonText='Select' endIcon={<FontAwesomeIcon icon={['fas', 'chevron-down']} />} menuItems={[
      {
        label: 'Publish Now', onClick: () => {
          setPublishItem({
            ...publishItem,
            show: true,
            data: reportData
          });
        }
      },
      {
        label: isAdmin ? 'Edit' : 'Reschedule', onClick: () => {
          navigate(editRoute);
        }
      },
      {
        label: 'Delete', onClick: () => {
          setDeleteItem({
            ...deleteItem,
            show: true,
            data: reportData
          });
        }
      }
    ]} />;
  };

  const handleFilingPageClick = (e, page) => {
    const params = {
      ...filingParams,
      page
    };
    loadFilingData(params);
  };

  const handleDelete = () => {
    const params = {
      userId: user.id
    };

    setErrors({
      ...errors,
      deleteItem: null
    });

    setDeleteItem({
      ...deleteItem,
      isDeleting: true
    });

    deleteFiling(deleteItem.data.id, params)
      .then(() => {
        const filingToUpdate = filingList?.records.find(obj => obj.id === deleteItem?.data.id);

        if (filingToUpdate) {
          filingToUpdate.statusId = 'D';
        }

        setDeleteItem({
          ...deleteItem,
          isDeleting: false,
          isDeleted: true
        });

        // refresh grid
        loadFilingData(filingParams);
      })
      .catch(error => setErrors({
        ...errors,
        deleteItem: error?.response?.data?.message || 'Failed to delete report. Try again.'
      }));
  };

  const handleCloseDeleteDialog = () => {
    setDeleteItem({ ...deleteItem, show: false });
    setErrors({ ...errors, deleteItem: null });
  };

  const handlePublish = () => {
    const updateParams = {
      [FINANCIAL_FORM_FIELDS.IS_IMMEDIATE]: true,
      userId: user.id
    };

    setErrors({
      ...errors,
      publishItem: null
    });

    setPublishItem({
      ...publishItem,
      isPublishing: true
    });

    updateFiling(publishItem.data.id, updateParams)
      .then(() => {
        setPublishItem({
          ...publishItem,
          isPublishing: false,
          isPublished: true
        });
        // refresh grid
        loadFilingData(filingParams);

      })
      .catch(error => setErrors({
        ...errors,
        publishItem: error?.response?.data?.message || 'Failed to Update report. Try again.'
      }));
  };

  const handleClosePublishDialog = () => {
    setPublishItem({ ...publishItem, show: false });
    setErrors({ ...errors, publishItem: null });
  };

  const handleStatusChange = e => {
    const { value } = e.target;
    const showAll = value === 'All';

    const params = {
      ...defaultFilingParams,
      status: value
    };

    if (showAll) delete params.status;

    setFilingList(null);
    loadFilingData(params);
  };

  return <div>
    <>
      {isAuthor && <Box sx={{ textAlign: 'right', my: 3 }}>
        <Link to={ROUTES.addFinancialReport.replace(':symbol', symbol)}>
          <Button endIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}>
            Add Financial Report
          </Button>
        </Link>
        <Link to={ROUTES.addFinancialReport.replace(':symbol', symbol) + '?lateFiling=true'}>
          <Button endIcon={<FontAwesomeIcon icon={['fas', 'plus']} />} gutterLeft>
            Notification of Late Filing
          </Button>
        </Link>
      </Box>}
      <Box mb={1}>
        <Card>
          <CardHeader>
            <CardTitle title='OTC Disclosure & News Service' icon={<FontAwesomeIcon icon={['far', 'file-lines']} />} />
            {isAuthor && <Box width={'150px'}>
              <SelectField
                disabled={isFilingLoading}
                onChange={handleStatusChange}
                options={statusOptions}
                value={'All'} />
            </Box>}
          </CardHeader>
          <CardContent>
            <Table isLoading={isFilingLoading} errorText={errors.filingList} columns={filingsColumns} data={filingList?.records || []} />
            {showPagination && <Box display='flex' justifyContent='flex-end' pt={2}>
              <Pagination disabled={isFilingLoading} page={filingList?.currentPage} count={filingList?.pages} onPageChange={handleFilingPageClick} />
            </Box>}
          </CardContent>
        </Card>
      </Box>
      <Box mb={1}>
        <SecFilingTable symbol={symbol} />
      </Box>
    </>
    <ActionDialog
      open={deleteItem.show}
      errorMessage={errors.deleteItem}
      isProcessing={deleteItem.isDeleting}
      isComplete={deleteItem.isDeleted}
      title={`Delete ${deleteItem?.data?.reportType}`}
      content={deleteDialogContent}
      onClose={handleCloseDeleteDialog}
      onDelete={handleDelete}
    />
    <ActionDialog
      open={publishItem.show}
      errorMessage={errors.publishItem}
      isProcessing={publishItem.isPublishing}
      isComplete={publishItem.isPublished}
      title='Publish Now'
      content={publishDialogContent}
      onClose={handleClosePublishDialog}
      onDelete={handlePublish}
    />
  </div>;
};

DisclosurePage.propTypes = {
  params: PropTypes.object,
  navigate: PropTypes.func
};

export default withRouter(DisclosurePage);
