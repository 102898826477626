import { isSafeAfterDate, isFutureDate } from '../../utlis/helper';
import { isEmptyString } from '../../utlis/validations';
import { FINANCIAL_FORM_FIELDS, FORM_FIELD_ERROR } from '../../constants';
import { reportTypeTg32 } from '../../constants/reportTypes';

const isTG32 = type => type === reportTypeTg32;

const isValidReportType = (data, errObj) => {
    const validReportType = !!data[FINANCIAL_FORM_FIELDS.TYPE];
    if (!validReportType) errObj[FINANCIAL_FORM_FIELDS.TYPE] = FORM_FIELD_ERROR.required;
    return validReportType;
};

const isValidPED = (data, errObj) => {
    if (data[FINANCIAL_FORM_FIELDS.PED] === FINANCIAL_FORM_FIELDS.OTHER_PED) {
        const otherPed = data[FINANCIAL_FORM_FIELDS.OTHER_PED];
        const isFutureDateTime = isFutureDate(otherPed);

        if (!otherPed) {
            errObj[FINANCIAL_FORM_FIELDS.OTHER_PED] = FORM_FIELD_ERROR.required;
            return false;
        };

        if (isFutureDateTime) {
            errObj[FINANCIAL_FORM_FIELDS.OTHER_PED] = FORM_FIELD_ERROR.maxDate;
            return false;
        };

        if (!isFutureDateTime) { return true; };

        errObj[FINANCIAL_FORM_FIELDS.OTHER_PED] = FORM_FIELD_ERROR.invalidDate;
        return false;
    }
    let validPed = !!data[FINANCIAL_FORM_FIELDS.PED];
    if (!validPed) errObj[FINANCIAL_FORM_FIELDS.PED] = FORM_FIELD_ERROR.required;
    return validPed;
};

const isFileValid = (files, errObj) => {
    if (!files || files.length === 0) {
        errObj['file'] = FORM_FIELD_ERROR.required;
        return false;
    };

    if (files && files.length > 0) return true;
};

const isValidAmendReason = (data, errObj) => {
    const amendReason = data[FINANCIAL_FORM_FIELDS.REASON_AMEND];
    if (amendReason && amendReason.length > 0) return true;

    errObj[FINANCIAL_FORM_FIELDS.REASON_AMEND] = FORM_FIELD_ERROR.required;
    return false;
};

const isValidPublishAdd = (data, errObj) => {
    const publishDate = data[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] || '';

    if (publishDate === '' && (data[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE] === 'true' || data[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE] === true)) {
        return true;
    }
    if (!publishDate) {
        errObj[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] = FORM_FIELD_ERROR.required;
        return false;
    }

    const isSameOrAfter = isSafeAfterDate(publishDate);

    if (!isSameOrAfter) {
        errObj[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] = FORM_FIELD_ERROR.minDate;
        return false;
    }

    if (isSameOrAfter) return true;

    errObj[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] = FORM_FIELD_ERROR.invalidDate;
    return false;
};

const isValidPublishEdit = (data, errObj) => {
    const publishDate = data[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] || '';


    if (data[FINANCIAL_FORM_FIELDS.STATUS]?.id === 'A') {

        if (!publishDate) {
            errObj[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] = FORM_FIELD_ERROR.required;
            return false;
        }

        const isSameOrAfter = isSafeAfterDate(publishDate);

        if (isSameOrAfter) {
            errObj[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] = FORM_FIELD_ERROR.maxDate;
            return false;
        }

        if (!isSameOrAfter) return true;

        errObj[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] = FORM_FIELD_ERROR.invalidDate;
        return false;
    }
    else {
        if (publishDate === '' && (data[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE] === 'true' || data[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE] === true)) {
            return true;
        }

        if (!publishDate) {
            errObj[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] = FORM_FIELD_ERROR.required;
            return false;
        }
        const isFutureDateTime = isFutureDate(publishDate);
        if (!isFutureDateTime) {
            errObj[FINANCIAL_FORM_FIELDS.PUBLISH_DATE] = FORM_FIELD_ERROR.minDate;
            return false;
        }
        return true;
    }

};

const isValidTG32 = (data, errObj) => {
    const manitainListing = !!data[FINANCIAL_FORM_FIELDS.MAINTAIN_LISTING] && data[FINANCIAL_FORM_FIELDS.MAINTAIN_LISTING] === 'true';
    if (!manitainListing) errObj[FINANCIAL_FORM_FIELDS.MAINTAIN_LISTING] = 'Yes Required';

    const forgeinPrivateIssuer = !!data[FINANCIAL_FORM_FIELDS.FOREIGN_PRIV_ISSUER] && data[FINANCIAL_FORM_FIELDS.FOREIGN_PRIV_ISSUER] === 'true';
    if (!forgeinPrivateIssuer) errObj[FINANCIAL_FORM_FIELDS.FOREIGN_PRIV_ISSUER] = 'Yes Required';

    const publishedExchangeAct = !!data[FINANCIAL_FORM_FIELDS.PUBLISH_EX_ACT] && data[FINANCIAL_FORM_FIELDS.PUBLISH_EX_ACT] === 'true';
    if (!publishedExchangeAct) errObj[FINANCIAL_FORM_FIELDS.PUBLISH_EX_ACT] = 'Yes Required';

    const tg32Certifed = !isEmptyString(data[FINANCIAL_FORM_FIELDS.TG32_CERTIFY]);
    if (!tg32Certifed) errObj[FINANCIAL_FORM_FIELDS.TG32_CERTIFY] = FORM_FIELD_ERROR.required;

    return manitainListing && forgeinPrivateIssuer && publishedExchangeAct && tg32Certifed;
};

export const validateAddForm = (data, files, errObj) => {
    const hasValidPublish = isValidPublishAdd(data, errObj);
    const hasValidPed = isValidPED(data, errObj);

    if (isTG32(data[FINANCIAL_FORM_FIELDS.TYPE])) return hasValidPublish && hasValidPed;

    const hasValidReportType = isValidReportType(data, errObj);
    const hasValidFile = isFileValid(files, errObj);
    return hasValidPublish && hasValidReportType && hasValidPed && hasValidFile;
};

export const validateAmendForm = (data, files, errObj) => {
    const hasValidStandardForm = validateAddForm(data, files, errObj);
    const hasAmendReason = isValidAmendReason(data, errObj);

    return hasValidStandardForm && hasAmendReason;
};

export const validateAdminEditForm = (data, errObj) => {
    const hasValidPublish = isValidPublishEdit(data, errObj);
    const hasValidReportType = isValidReportType(data, errObj);
    const hasValidPed = isValidPED(data, errObj);

    return hasValidPublish && hasValidReportType && hasValidPed;
};

export const validateNonAdminEditForm = (data, errObj) => {
    const hasValidPublish = isValidPublishEdit(data, errObj);
    return hasValidPublish;
};

export const validateTG32 = (data, errObj) => {
    const hasValidPublish = isValidPublishAdd(data, errObj);
    const hasValidPed = isValidPED(data, errObj);
    const hasValidTG32 = isValidTG32(data, errObj);

    return hasValidPublish && hasValidPed && hasValidTG32;
};
