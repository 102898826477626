import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from '@react-pdf/renderer';

const SectionHeader = ({ text }) => (
  <Text style={styles.header}>
    {text}
  </Text>
);

const styles = StyleSheet.create({
  header: {
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'uppercase',
    borderBottom: '1px solid #D4D4D4',
    paddingBottom: 8,
    marginTop: 24,
    marginBottom: 16
  }
});

SectionHeader.propTypes = {
  text: PropTypes.string
};

export default SectionHeader;
