import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/Auth.context';
import { CompanyProvider } from './context/Company.context';
import { DisclosureProvider } from './context/Disclosure.context';
import { ThemeProvider } from './context/Theme.context';
import DnsLayout from './container/DnsLayout';
import Layout from './components/Layout';
import AddMediaPage from './pages/AddMediaPage';
import MediaPage from './pages/MediaPage';
import ResearchReportPage from './pages/ResearchReportPage';
import DisclosurePage from './pages/DisclosurePage';
import AddFinancialReport from './pages/AddFinancialReport';
import AddResearchReport from './pages/AddResearchReport';
import NotFoundPage from './pages/NotFoundPage';
import { ROUTES } from './constants/routes';

const App = () => {
  return (
    <div className='otc-theme'>
      <ThemeProvider>
        <AuthProvider>
          <CompanyProvider>
            <DisclosureProvider>
              <Routes>
                <Route path='*' element={<NotFoundPage />} />
                <Route path={ROUTES.media} element={<Layout><MediaPage /></Layout>} />
                <Route path={ROUTES.addMedia} element={<Layout><AddMediaPage /></Layout>} />
                <Route path={ROUTES.researchReport} element={<Layout><ResearchReportPage /></Layout>} />
                <Route path={ROUTES.addResearchReport} element={<Layout><AddResearchReport /></Layout>} />
                <Route path={ROUTES.disclosures} element={<DnsLayout><DisclosurePage /></DnsLayout>} />
                <Route path={ROUTES.addFinancialReport} element={<DnsLayout><AddFinancialReport /></DnsLayout>} />
                <Route path={ROUTES.amendFinancialReport} element={<DnsLayout><AddFinancialReport /></DnsLayout>} />
                <Route path={ROUTES.editFinancialReport} element={<DnsLayout><AddFinancialReport /></DnsLayout>} />
              </Routes>
            </DisclosureProvider>
          </CompanyProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
