import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import queryString from 'query-string';

dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);


export const getQueryObj = search => {
  return queryString.parse(search);
};

export const redirectIQ = _ => window.location.href = 'https://www.otciq.com/home';

export const isWeekend = date => {
  const createDate = new Date(date);
  const dayOfWeek = createDate.getDay();
  return dayOfWeek === 0 || dayOfWeek === 6;
};

export const disableDates = args => {
  const dayOfWeek = args.date.getDay();
  const isWeekend = args.view === 'month' && (dayOfWeek === 0 || dayOfWeek === 6);

  return isWeekend;
};

export const setSessionStorage = (key, value) => {
  return new Promise((resolve, reject) => {
    try {
      sessionStorage.setItem(key, value);
      resolve('Successfully set ' + key);
    } catch (error) {
      reject('Error setting ' + key + ': ' + error.message);
    }
  });
};

export const createID = array => {
  const id = Math.floor(Math.random() * 90000) + 10000;
  if (array && array.find(item => item.id === id)) {
    createID(array);
  } else {
    return id;
  }
};

export const createArrayList = str => {
  if (str.includes(',') || str.includes(';')) {
    return str.split(/[;,]/).map(word => word.trim()).filter(word => word !== '');
  } else {
    return [str];
  }
};


export const dynamicSort = (property, type = 'string', sortDir) => {
  property = property.toString();

  return function (a, b) {
    const aProperty = a[property];
    const bProperty = b[property];

    if (type === 'number') {
      if (sortDir === 'DESC') return bProperty - aProperty;
      else return aProperty - bProperty;
    }
    if (type === 'date') {
      if (sortDir === 'DESC') return new Date(bProperty) - new Date(aProperty);
      else return new Date(aProperty) - new Date(bProperty);
    }
    if (type === 'string') {
      if (sortDir === 'DESC') {
        if (!a[property]) return -1;
        if (!b[property]) return 0;
        return b[property].localeCompare(a[property]);
      } else {
        if (!a[property]) return 0;
        if (!b[property]) return -1;
        return a[property].localeCompare(b[property]);
      }
    }
  };
};

export const paginate = (array = [], pageSize = 5, pageNumber = 1) => {
  --pageNumber; // because pages logically start with 1, but technically with 0
  return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
};

export const isFutureDate = datetime => {
  const date = new Date(datetime);
  const now = new Date();

  // Check if the date is valid
  if (isNaN(date)) {
    throw new Error('Invalid date format');
  }

  return date > now;
};

export const isBeforeDate = datetime => {
  const now = dayjs();
  return dayjs(datetime).isBefore(now);
};

export const isTodayDate = date => {
  return dayjs(date).isToday();
};

export const isEmptyArray = (array) => {
  return !array || array.length === 0;
};

export const isSafeAfterDate = datetime => {
  const date = new Date(datetime);
  const now = dayjs().second(0).millisecond(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return dayjs(date).isSameOrAfter(now);
};

export const getGUID = id => {
  let idArray = '';
  try {
    idArray = id.split(/-/);
  } catch (err) {
    return '';
  }

  // const idArray = id.split(/-/)
  let guid = '';

  if (idArray.length === 3) {
    guid = idArray[1];
    return guid;
  }

  idArray.map((item, index) => {
    if (index !== 0 && index !== idArray.length - 1) guid += item + '-';
  });

  guid = guid.substring(0, guid.length - 1);
  return guid;
};
