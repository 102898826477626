import React from 'react';
import PropTypes from 'prop-types';
import { OTC_BASE_URL } from '../../constants';
import { Box } from '@mui/material';
import { getGUID } from '../../utlis/helper';
import { Button } from '@otcmarketsgroup/otcm-ui-lib';

const ViewCell = ({ item }) => {
    return <Box>
        <a href={`${OTC_BASE_URL}/filing/html?id=${item.id}&guid=${getGUID(item.guid)}`} target='_blank' rel='noopener'>
            <Button variant='text' gutterRight>
                HTML
            </Button>
        </a>
        <a href={`${OTC_BASE_URL}/filing/conv_pdf?id=${item.id}&guid=${getGUID(item.guid)}`} target='_blank' rel='noopener'>
            <Button variant='text' gutterRight>
                PDF
            </Button>
        </a>
        <a href={`${OTC_BASE_URL}/filing/rtf?id=${item.id}&guid=${getGUID(item.guid)}`} target='_blank' rel='noopener'>
            <Button variant='text' gutterRight>
                DOC
            </Button>
        </a>
        {item.hasExcel && <a href={`${OTC_BASE_URL}/filing/xls?id=${item.id}&guid=${getGUID(item.guid)}`} target='_blank' rel='noopener'>
            <Button variant='text'>
                XLS
            </Button>
        </a>}
    </Box>;
};

ViewCell.propTypes = {
    item: PropTypes.object
};

export default ViewCell;
