import React from 'react';
import PropTypes from 'prop-types';
import { FINANCIAL_FORM_FIELDS, TG32_QUESTIONS } from '../../constants';
import { Box } from '@mui/material';
import { TextField, RadioGroup, Typography, } from '@otcmarketsgroup/otcm-ui-lib';

const TG32Form = ({ formData, onChange, validationErrors }) => {
    return (
        <div>
            <Typography variant='h2'>
                12g3-2(b) Certification
            </Typography>
            <Typography variant='body1' mt={2}>
                Please answer the three questions below to confirm your company is eligible to rely on the exemption from registration under Securities Exchange Act Rule 12g3-2(b).
            </Typography>
            <Typography variant='body1' mt={2}>
                This information will be used to determine compliance with SEC Rule 15c2-11 for public quotations by broker dealers, with U.S. state blue sky regulations, and with OTCQX and OTCQB Rules where applicable. Your certification document is for internal use only and will not be publicly available. If you have questions regarding your status under Rule 12g3-2(b), please work with your securities counsel.
            </Typography>
            <Box py={2}>
                {TG32_QUESTIONS.MAINTAIN_LISTING}
                <RadioGroup
                    name={FINANCIAL_FORM_FIELDS.MAINTAIN_LISTING}
                    value={formData[FINANCIAL_FORM_FIELDS.MAINTAIN_LISTING]}
                    row
                    onClick={onChange}
                    options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                    ]}
                />
                {formData[FINANCIAL_FORM_FIELDS.MAINTAIN_LISTING] == 'false' && <Typography variant='subtitle1' color='warning'>
                    The Company must maintain a listing for its securities on a non-U.S. stock exchange in order to rely on the exemption from registration provided by SEC Rule 12g3-2(b). Please contact Issuer Services at 212-896-4420 to discuss your company’s compliance options.
                </Typography>}
            </Box>
            <Box py={2}>
                {TG32_QUESTIONS.FOREIGN_PRIV_ISSUER.text}
                <Typography variant='body1' mt={2}>
                    {TG32_QUESTIONS.FOREIGN_PRIV_ISSUER.subText}
                </Typography>
                <ul>
                    {TG32_QUESTIONS.FOREIGN_PRIV_ISSUER.unorderedList.map(listItem => <li key={listItem.text}>
                        {listItem.text}
                        {listItem.unorderedList && <ul>
                            {listItem.unorderedList.map(ulist => <li key={ulist.text}>
                                {ulist.text}
                            </li>)}
                        </ul>}
                    </li>)}
                </ul>
                <RadioGroup
                    name={FINANCIAL_FORM_FIELDS.FOREIGN_PRIV_ISSUER}
                    value={formData[FINANCIAL_FORM_FIELDS.FOREIGN_PRIV_ISSUER]}
                    row
                    onClick={onChange}
                    options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                    ]}
                />
                {formData[FINANCIAL_FORM_FIELDS.FOREIGN_PRIV_ISSUER] === 'false' && <Typography variant='subtitle1' color='warning'>
                    The Company must be a Foreign Private Issuer in order to rely on the exemption from registration provided by SEC Rule 12g3-2(b). Please contact Issuer Services at 212-896-4420 to discuss your company’s compliance options.
                </Typography>}
            </Box>
            <Box py={2}>
                {TG32_QUESTIONS.PUBLISH_EX_ACT.text}
                <ul>
                    {TG32_QUESTIONS.PUBLISH_EX_ACT.unorderedList.map(ulist => <li key={ulist.text}>
                        {ulist.text}
                    </li>)}
                </ul>
                <RadioGroup
                    name={FINANCIAL_FORM_FIELDS.PUBLISH_EX_ACT}
                    value={formData[FINANCIAL_FORM_FIELDS.PUBLISH_EX_ACT]}
                    row
                    onClick={onChange}
                    options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                    ]}
                />
                {formData[FINANCIAL_FORM_FIELDS.PUBLISH_EX_ACT] === 'false' && <Typography variant='subtitle1' color='warning'>
                    The Company must publish information required to be made publicly available pursuant to the Exchange Act Rule 12g3-2(b) in order to complete this certification. Please publish the required information before you continue. Contact Issuer Services at 212-896-4420 for further information.
                </Typography>}
            </Box>
            <Box py={2}>
                <Box mb={2}>
                    {TG32_QUESTIONS.TG32_CERTIFY}
                </Box>
                <TextField
                    label='Signature'
                    name={FINANCIAL_FORM_FIELDS.TG32_CERTIFY}
                    error={validationErrors[FINANCIAL_FORM_FIELDS.TG32_CERTIFY]}
                    helperText={validationErrors[FINANCIAL_FORM_FIELDS.TG32_CERTIFY]}
                    value={formData[FINANCIAL_FORM_FIELDS.TG32_CERTIFY] || ''}
                    placeholder='Type your name'
                    maxLength={500}
                    onChange={onChange}
                />
            </Box>
        </div>
    );
};

TG32Form.propTypes = {
    formData: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    validationErrors: PropTypes.object
};

export default TG32Form;
