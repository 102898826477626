import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, Link, Text, View } from '@react-pdf/renderer';
import { format } from '../utlis/locale';
import styles from './PDF.styles';

const TemplateAmend = ({ companyName, reportType, title, ped, publishDate, reasonText,link }) => {
  const publishDateTime = `${format(publishDate, publishDate, 'date')}`;

  return (
    <Document style={styles.document}>
      <Page style={styles.page} size='A4'>
        <Text style={styles.header}>
          {companyName}
        </Text>
        <Text style={{ textAlign: 'center', fontSize: '16px' }}>
          Amendment to {reportType} {title} for {ped} originally published through the OTC Disclosure & News Service on <Link style={{ color: '#00a88f' }} src={link}>{publishDateTime}</Link>
        </Text>
        <View style={{ textAlign: 'center', fontSize: '14px', padding: '24px 0' }}>
          <Text style={{ fontWeight: 600 }}>
            Explanatory Note:
          </Text>
          <Text>
            {reasonText}
          </Text>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>
            **This coversheet was automatically generated by OTC Markets Group based on the information provided by the Company. OTC Markets Group has not reviewed the contents of this amendment and disclaims all responsibility for the information contained herein.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

TemplateAmend.propTypes = {
  companyName: PropTypes.string,
  reportType: PropTypes.string,
  title: PropTypes.string,
  ped: PropTypes.string,
  publishDate: PropTypes.any,
  reasonText: PropTypes.string,
  link: PropTypes.string,
};

export default TemplateAmend;
