import moment from 'moment-timezone';

const numberWithCommas = (x) => {
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export function format(value, placeholder, type, shorten) {
  if (value !== null && value !== undefined) {
    switch (type) {
      case 'int':
        if (typeof value === 'string') value = parseFloat(value);
        value = numberWithCommas(value.toFixed(0));
        break;

      case 'seconds':
        if (typeof value === 'string') value = parseFloat(value);
        if (value < 1) value = '< 1s';
        if (value >= 1) value = `${numberWithCommas(value.toFixed(0))}s`;
        break;

      case 'float':
        if (typeof value === 'string') value = parseFloat(value);
        if (isNaN(value)) return placeholder;
        value = numberWithCommas(value.toFixed(2));
        break;

      case 'price':
        value = parseFloat(value);
        if (isNaN(value)) return placeholder;
        value = numberWithCommas(value.toFixed(6).replace(/0{0,4}$/, ''));
        break;

      case 'millions':
        value = parseFloat(value);
        if (isNaN(value)) return placeholder;
        value = value / 1000000.0;
        value = numberWithCommas(value.toFixed(2));
        break;

      case 'percentage':
        value = parseFloat(value);
        if (isNaN(value)) return placeholder;
        value = numberWithCommas(value.toFixed(2));
        if (value === '-0.00') value = '0.00';
        if (value.length > 0) value += '%';
        break;

      case 'tenths':
        value = parseFloat(value);
        if (isNaN(value)) return placeholder;
        value = numberWithCommas(value.toFixed(1));
        if (value === '-0.00') value = '0.00';
        if (value.length > 0) value += '%';
        break;

      case 'sign':
        const sign = parseFloat(value) > 0 ? '+' : '';
        value = numberWithCommas(value.toFixed(2));
        if (value.length > 0) value = sign + value;
        break;

      case 'bool':
        const isTrue = value === true || value === 'true';
        value = isTrue ? 'Yes' : 'No';
        break;

      case 'date':
        value = moment(value).tz('America/New_York').format('MM/DD/YYYY');
        break;

      case 'date_MM_DD_YYYY':
        value = moment(value).format('MM/DD/YYYY');
        break;

      case 'time':
        value = moment(value).tz('America/New_York').format('hh:mm a');
        break;

      case 'isoDate':
        value = moment(value).tz('America/New_York').format('YYYY-MM-DD');
        break;

      case 'apiDate':
        value = moment(value).format('YYYY-MM-DD');
        break;

      case 'estDate':
        value = moment.tz(value, 'YYYY-MM-DD', 'America/New_York').format('MM/DD/YYYY');
        break;

      case 'isoTime':
        value = moment(value).tz('America/New_York').format('hh:mm');
        break;

      case 'isoEasternString':
        value = moment(value).tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss.SSS');
        break;

      case 'dateTime':
        value = moment(value).tz('America/New_York').format('MM/DD/YYYY hh:mm a');
        break;

      case 'staticDate':
        value = moment(value).format('MM/DD/YYYY');
        break;

      case 'text':
        break;

      case 'byteSize':
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (value === 0) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(value) / Math.log(1024)));
        return Math.round(value / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
  } else {
    value = placeholder;
  }

  return value;
}

export const checkNegative = (value) => {
  const NA = 'N/A';

  if (typeof value === 'string') {
    if (value.includes('-')) return NA;
  }

  if (typeof value === 'number') {
    if (Math.sign(value) === -1) return NA;
  }

  return value;
};
