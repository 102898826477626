import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { getQueryObj, redirectIQ, setSessionStorage } from '../utlis/helper';
import { logUser, validateSession } from '../api/auth';
import { storage } from '../constants';

export const AuthContext = createContext();

export const AuthConsumer = AuthContext.Consumer;

const initialState = {
  isReady: false,
  isAuthenticated: false,
  hasStorageToken: false,
  symbol: null,
  invalidSymbol: null,
  user: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_AUTH':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      };
    case 'SET_SYMBOL':
      return {
        ...state,
        symbol: action.payload
      };
    case 'SET_COMPANY_NAME':
      return {
        ...state,
        user: {
          ...state.user,
          companyName: action.payload
        }
      };
    case 'SET_INVALID_SYMBOL':
      return {
        ...state,
        invalidSymbol: true
      };
    default:
      throw new Error();
  }
};

export const AuthProvider = props => {
  const [authState, authDispatch] = useReducer(reducer, initialState);
  const sessionStorageToken = window.sessionStorage.getItem(storage.TOKEN);
  const queryToken = getQueryObj(location.search).token;

  const validateUser = (token, logUserAccess) => {
    validateSession(token, authState.symbol)
      .then(data => {
        logUserAccess && logUser(data.email);

        setSessionStorage(storage.TOKEN, token)
          .then(() => {
            authDispatch({
              type: 'SET_AUTH',
              payload: data
            });
          })
          .catch((errorMessage) => {
            console.error(errorMessage);
          });
      })
      .catch(e => {
        redirectIQ(); // Session Token validation failed; redirect to IQ
      });
  };

  useEffect(() => {
    if (queryToken) {
      !!authState.symbol && validateUser(queryToken, true);
    } else if (sessionStorageToken) {
      !!authState.symbol && validateUser(sessionStorageToken);
    } else {
      redirectIQ();
    }
  }, [authState.symbol]);

  return (
    <AuthContext.Provider value={[authState, authDispatch]}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
