export const reportTypeAlci = 'ALCI'; // 'Attorney Letter with Respect to Shell Status',

export const reportTypeTg32 = 'TG32'; // Tg 32',

export const reportTypesGrouping = [
    {
        title: 'Periodic Reporting Documents',
        options: [
            'AR',
            'QR',
            'IFR',
            'SAR',
            'MDA',
            'NLF',
            'PXM',
            'PXST',
        ]
    },
    {
        title: 'Certifications',
        options: [
            reportTypeTg32,
            'QBAC'
        ]
    },
    {
        title: 'Other Document Types',
        options: [
            '144O',
            '144P',
            'NCSH',
            'BRAQ',
            'ODD',
            'STIA',
            'ICAR',
            'PR',
            'RDOA',
            'RDP',
            'SPA',
            reportTypeAlci,
            'SI',
        ]
    }
];

export const reportTypesAllowOtherPed = [
    'IFR',
    'MDA',
    'NLF',
    'PXM',
    'PXST',
    '144O',
    '144P',
    '_144O',// 1440 and _1440 means same
    '_144P', // 144P and _144P means same
    'NCSH',
    'BRAQ',
    'ODD',
    'STIA',
    'PR',
    'RDOA',
    'RDP',
    'SPA',
    'SI',
];

export const disableEditReportTypes = [
    'MC',
    'CIDS',
    'BCDP',
    'BFD',
    'BRAA',
    'CB',
    'LO',
    'ACIO',
    'ANPS',
    'F211',
    'AOSP',
    'ARTI',
    'ARSI',
    'ICS',
    'DP',
    'BACS',
    'QBIC',
    'CALL',
    'ACBL',
    'LOI',
    'IIL',
    'HCPR',
];