import React from 'react';
import { Text } from '@react-pdf/renderer';

export const LOADING = 'Loading ...';

export const OTC_BASE_URL = 'https://www.otcmarkets.com';

export const TODAY_DATE = new Date();

export const MAX_FILE_SIZE = {
    '50MB': 52428800
};

export const storage = {
    TOKEN: 'token',
    SYMBOL: 'symbol'
};

export const FINANCIAL_FORM_FIELDS = {
    TYPE: 'type',
    PED: 'periodEndDate',
    SIMILAR_FILING: 'similarFiling',
    TITLE: 'title',
    IS_IMMEDIATE: 'isImmediate',
    PUBLISH_DATE: 'releaseDate',
    PUBLISH_TIME: 'releaseTime',
    REASON_AMEND: 'amendmentReason',
    IS_AMEND: 'isAmendment',
    AMEND_REPORT_ID: 'amendedReportId',
    OTHER_PED: 'otherPed',
    STATUS: 'reportStatus',
    MAINTAIN_LISTING: 'maintainQfeListing',
    FOREIGN_PRIV_ISSUER: 'forgeinPrivateIssuer',
    PUBLISH_EX_ACT: 'publishedExchangeAct',
    TG32_CERTIFY: 'tg32Certifed',
};

export const FORM_FIELD_ERROR = {
    required: 'Required',
    minDate: 'Can not be a Past Date',
    maxDate: 'Can not be a Future Date',
    invalidDate: 'invalid date',
    minTime:'Can not be a Past Time',
    maxTime: 'Can not be a Future Time',
    invalidTime: 'invalid time'
};

export const HELPER_TEXT = {
    PED: `Period End Date is based on Company's Fiscal Year End and Reporting Schedule.`,
    ALCI_PED_EMPTY: 'No annual report(s) found within the last three years. Attorney Letters must be associated with a specific Annual Report.'
};

export const STATUS_MAP = {
    A: 'Active',
    P: 'Scheduled',
    U: 'Unknown',
    D: 'Deleted',
    I: 'Inactive',
    B: 'Denied',
    S: 'Pending'
};

export const TG32_QUESTIONS = {
    MAINTAIN_LISTING: '1. The Company currently maintains a listing for its securities on a non-U.S. stock exchange.',
    FOREIGN_PRIV_ISSUER: {
        text: '2. The Company meets the definition of a "Foreign Private Issuer."',
        subText: 'A Foreign Private Issuer is defined as a company that is incorporated or organized under the laws of a jurisdiction outside of the United States and either of the following two conditions apply:',
        unorderedList: [
            {
                text: '50% or less of its outstanding voting securities are held directly or indirectly by U.S. residents; or'
            },
            {
                text: 'More than 50% of its outstanding voting securities are held directly or indirectly by U.S. residents and none of the following are true:',
                unorderedList: [
                    {
                        text: 'the majority of its executive officers or directors are U.S. citizens or residents;'
                    },
                    {
                        text: `more than 50% of the company's assets are located in the United States; or`
                    },
                    {
                        text: `the company's business is administered principally in the United States`
                    }
                ]
            }
        ]
    },
    PUBLISH_EX_ACT: {
        text: '3. The Company has published, in English, the information required to be made publicly available pursuant to Exchange Act Rule 12g3-2(b) in the following locations:',
        unorderedList: [
            {
                text: <>The Company website or a website generally available to the public in its primary trading market; <span style={{ textDecoration: 'underline' }}>and</span></>,
                textPdf: <>The Company website or a website generally available to the public in its primary trading market; <Text style={{ textDecoration: 'underline' }}>and</Text></>
            },
            {
                text: `The OTC Disclosure & News Service or an Integrated Newswire (for press releases)`
            }
        ]
    },
    TG32_CERTIFY: 'By typing my name into the “Signature” section below, I certify that I am duly authorized by the Company to execute this 12g3-2(b) Certification and that the information contained herein is true and correct.'
};