import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getConfig from '../../config';
import Locale from '../../components/Locale';
import ImageWithFallback from '../../components/ImageWithFallback';
import styles from './CompanyHeader.module.scss';
import { Card } from '@otcmarketsgroup/otcm-ui-lib';
const { OTC_URL } = getConfig();

const CompanyHeader = ({ symbol, profile, inside }) => {
    const [loaded, setIsLoaded] = useState(false);
    const [noInside, setNoInside] = useState(false);
    const [unlisted, setUnlisted] = useState(false);
    const isPositive = inside?.change > 0;
    const isNegative = inside?.change < 0;
    let isQxQbTier = false;

    // START TIER/THEME INFO
    let accentColor = null;
    let tierName = null;

    if (profile) {
        switch (profile.tierGroup) {
            case 'QX':
                tierName = 'OTCQX';
                accentColor = 'green';
                isQxQbTier = true;
                break;

            case 'DQ':
                tierName = 'OTCQB';
                isQxQbTier = true;
                accentColor = 'orange';
                break;

            case 'PS':
                tierName = 'Pink';
                accentColor = 'pink';
                break;

            case 'OO':
                tierName = 'Grey Market';
                accentColor = 'gray';
                break;

            case 'EM':
                tierName = 'OTC Expert Market';
                accentColor = 'gray';
                break;

            default:
                tierName = profile.venue || 'Listed';
                accentColor = 'black';
                break;
        }

        profile.tierName = tierName;
    }

    let tierImage = null;
    let tierImageAltText = null;
    switch (profile?.currentSecurity?.tierId || 0) {
        case 1:
        case 2:
        case 5:
        case 6:
            tierImage = 'QX';
            tierImageAltText = 'OTCQX Best Market Logo';
            break;

        case 10:
            tierImage = 'QB';
            tierImageAltText = 'OTCQB Venture Market Logo';
            break;

        case 20:
            tierImage = 'PS';
            tierImageAltText = 'Pink Open Market Logo';
            break;

        case 21:
            tierImage = 'yield';
            tierImageAltText = 'Limited Information Icon';
            break;

        case 22:
            tierImage = 'stop';
            tierImageAltText = 'No Information Icon';
            break;

        case 30:
            tierImage = 'caution';
            tierImageAltText = 'Grey Market Logo';
            break;

        case 40:
            tierImage = 'expert-tier';
            tierImageAltText = 'Expert Market Icon';
            break;

        default:
            break;
    }
    if (profile && profile.isCaveatEmptor) {
        tierImage = 'caveat-emptor';
        tierImageAltText = 'Caveat Emptor Icon';
    }

    const tierImageUrl = tierImage ? `/logos/tier/${tierImage}.png` : null;

    // Awards
    let awardImageUrl;
    if (profile && profile.tierGroup === 'QX' && profile.otcAward && profile.otcAward.best50) {
        awardImageUrl = '/logos/OTCQX_Best_50.png';
        tierImageAltText = 'OTCQX Best 50 Logo';
    }
    if (profile && profile.tierGroup === 'QX' && profile.otcAward && profile.otcAward.tenYear) {
        awardImageUrl = '/logos/OTCQX_10_Years.png';
        tierImageAltText = 'OTCQX 10 Year Anniversary Logo';
    }

    const showTierDisplayName = profile &&
        profile.currentSecurity &&
        profile.currentSecurity.tierDisplayName &&
        profile.currentSecurity.tierId &&
        profile.currentSecurity.tierId !== 0;

    let basicInfo = null;

    if (profile) {
        // Tier member since
        switch (profile.tierGroup) {
            // OTCQX
            case 'QX':
                basicInfo = `Joined OTCQX ${moment(profile.tierStartDate).format('MM/YYYY')}`;
                break;

            // OTCQB
            case 'DQ':
                basicInfo = `Joined OTCQB ${moment(profile.tierStartDate).format(
                    'MM/YYYY'
                )}`;
                break;
        }

        // Tier name
        tierName = (showTierDisplayName && profile.currentSecurity.tierDisplayName) || tierName;
    }

    useEffect(() => {
        if (!!profile && !!inside) {
            setNoInside(!profile.hasQuotes || (!inside.bidPrice && !inside.askPrice));
            setUnlisted(!profile.tierGroup);
            setIsLoaded(true);
        }
    }, [profile, inside]);

    return loaded ?
        <Card>
            <div className={styles.container}>
                <div className={styles.companyHeader}>
                    <div className={styles.companyLogoContainer}>
                        <ImageWithFallback className={styles.companyLogo} alt={`${profile?.name || symbol}`}  src={`${OTC_URL}/company-otcapi${profile?.companyLogoUrl}`} />
                    </div>
                    <div>
                        <div className={styles.companyName}>{profile?.name}</div>
                        <h2 className={styles.ticker}>{profile.tierName}:{symbol.toUpperCase()}</h2>
                        <div className={styles.subHeader}>
                            {profile?.currentSecurity?.className}
                            {profile?.currentSecurity?.statusName && <span>&nbsp;| Status:&nbsp;
                                <span className={cn({ [styles.suspendedStatus]: (profile.currentSecurity.statusName === 'Suspended' || profile.currentSecurity.statusName === 'Halted' || profile.currentSecurity.statusName === 'Internal Halt') })}>
                                    {profile.currentSecurity.statusName}
                                </span>
                            </span>}
                        </div>
                    </div>
                </div>
                {profile?.hasQuotes && (
                    <div className={styles.companyBid}>
                        <div>
                            <div
                                className={cn(styles.bid, {
                                    [styles.positive]: isPositive,
                                    [styles.negative]: isNegative,
                                    [styles.noChange]: !(
                                        inside?.change !== undefined &&
                                        (inside.change > 0 || inside.change < 0)
                                    ),
                                })}
                            >
                                <h2 className={styles.price}>
                                    <Locale type='price'>{inside?.lastSale}</Locale>
                                </h2>
                                {isPositive && <FontAwesomeIcon icon={['fas', 'up-long']} className={styles.iconArrow} />}
                                {isNegative && <FontAwesomeIcon icon={['fas', 'down-long']} className={styles.iconArrow} />}
                                {inside?.change !== undefined && (
                                    <div className={styles.evo}>
                                        <p>
                                            {inside.change !== undefined ? (
                                                <Locale type='price'>{inside.change}</Locale>
                                            ) : (
                                                ''
                                            )}
                                        </p>
                                        <p>
                                            {inside.change !== undefined ? (
                                                <Locale type='percentage'>
                                                    {inside.percentChange}
                                                </Locale>
                                            ) : (
                                                ''
                                            )}
                                        </p>
                                    </div>
                                )}
                            </div>

                            {noInside && !unlisted && (
                                <p className={styles.ask}>
                                    <strong>No Inside Bid / Offer</strong>
                                </p>
                            )}
                            {!noInside && !unlisted && (
                                <p className={styles.ask}>
                                    <strong>
                                        {inside?.bidPrice ? (
                                            <Locale type='price'>{inside.bidPrice}</Locale>
                                        ) : (
                                            'No Inside'
                                        )}{' '}
                                        /{' '}
                                        {inside?.askPrice ? (
                                            <Locale type='price'>{inside.askPrice}</Locale>
                                        ) : (
                                            'No Inside'
                                        )}
                                    </strong>{' '}
                                    {inside?.bidSize && inside?.askSize && (
                                        <span>
                                            ({inside.bidSize} x {inside.askSize})
                                        </span>
                                    )}
                                </p>
                            )}
                            <div className={styles.subHeader}>
                                {inside?.insideTime && (
                                    <div>
                                        Real-Time Best Bid & Ask:{' '}
                                        {moment(inside.insideTime)
                                            .tz('America/New_York')
                                            .format('hh:mma MM/DD/YYYY')}
                                    </div>
                                )}
                                {inside?.quoteDateTime && (
                                    <div>
                                        Delayed (15 Min) Trade Data:{' '}
                                        {moment(inside.quoteDateTime)
                                            .tz('America/New_York')
                                            .format('hh:mma MM/DD/YYYY')}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div className={cn(styles.info, styles.right)}>
                    <div key='profile' className={styles.profile}>
                        {(tierImageUrl || awardImageUrl || basicInfo) && (
                            <div className={styles.tierSection}>
                                {(tierImageUrl || awardImageUrl) && (
                                    <img
                                        src={awardImageUrl || tierImageUrl}
                                        className={awardImageUrl && styles.award}
                                        alt={tierImageAltText}
                                    />
                                )}
                                {basicInfo && <div className={styles.infoText}>{basicInfo}</div>}
                                {profile.indexStatuses && profile.indexStatuses.length > 0 && !isQxQbTier && <div key='tier' className={styles.tierText}>
                                    {tierName}
                                </div>}
                            </div>
                        )}
                        {!profile.indexStatuses.length && <div className={styles.infoText}>{tierName}</div>}
                    </div>
                </div>
            </div>
        </Card> :
        null;
};

CompanyHeader.propTypes = {
    symbol: PropTypes.string,
    profile: PropTypes.object,
    inside: PropTypes.object,
};

export default CompanyHeader;
