import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@otcmarketsgroup/otcm-ui-lib';

const ActionDialog = ({
    open,
    errorMessage,
    isProcessing,
    isComplete,
    title,
    content,
    onClose,
    onDelete,
}) => {

    return (
        <Dialog
            open={open}
            errorMessage={errorMessage}
            actions={!isComplete && [
                {
                    color: 'warning',
                    label: 'No',
                    disabled: isProcessing,
                    onClick: () => onClose(),
                    variant: 'outlined'
                },
                {
                    color: 'warning',
                    label: 'Yes',
                    onClick: () => onDelete(),
                    isLoading: isProcessing,
                    variant: 'contained'
                }
            ]}
            onClose={() => isProcessing ? undefined : onClose()}
            content={content}
            title={title}
        />
    );
};

ActionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    isProcessing: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default ActionDialog;
