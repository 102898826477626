import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';

const Row = ({ children }) => (
  <View style={styles.row}>
    {children}
  </View>
);

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '12px'
  }
});

Row.propTypes = {
  children: PropTypes.node
};

export default Row;
