export const ROUTES = {
    media: '/dns/video-presentations/:symbol',
    addMedia: '/dns/video-presentations/:symbol/add-media',
    researchReport: '/dns/research-report/:symbol',
    addResearchReport: '/dns/research-report/:symbol/add-report',
    disclosures: '/dns/disclosure/:symbol',
    addFinancialReport: '/dns/disclosure/:symbol/add-financial-report',
    amendFinancialReport: '/dns/disclosure/:symbol/add-financial-report/amend/:amendReportId',
    editFinancialReport: '/dns/disclosure/:symbol/edit-financial-report/:reportId',
};
