const validator = require('validator');
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isEmpty = value => {
  if (!value || value && typeof value === 'string' && value.trim().length === 0) {
    return true;
  } else {
    return false;
  }
};

module.exports = {
  isString: value => {
    if (value && typeof value === 'string' && value.trim().length > 0) {
      return true;
    }
    return false;
  },
  isEmptyString: value => {
    return isEmpty(value);
  },
  isInt: n => {
    return n % 1 === 0;
  },
  isNumber: value => {
    if (typeof value === 'number') {
      return true;
    }
    return false;
  },
  isPositiveNumber: value => {
    if (typeof value !== 'string') {
      return false;
    }
    return /^\d+$/.test(value);
  },
  isBoolean: value => {
    if (typeof value === 'boolean' || value === 'true' || value === 'false') {
      return true;
    }
    return false;
  },
  isObject: value => {
    if (typeof value === 'object') {
      return true;
    }
    return false;
  },
  isEmail: value => {
    if (isEmpty(value)) return true;

    return value.match(emailRegex);
  },
  isWebsite: value => {
    if (isEmpty(value)) return true;

    return validator.isURL(value);
  },
};
