// src/hooks/useCompanyData.js

import { useEffect, useContext } from 'react';
import { getCompanyProfile, getInsideData } from '../api/company';
import { AuthContext } from '../context/Auth.context';
import { CompanyContext } from '../context/Company.context';
import { storage } from '../constants';

export const useCompanyData = (symbol) => {
    const [authState, authDispatch] = useContext(AuthContext);
    const [, companyDispatch] = useContext(CompanyContext);

    useEffect(() => {
        window.sessionStorage.setItem(storage.SYMBOL, symbol);
        authDispatch({ type: 'SET_SYMBOL', payload: symbol });

        if (authState.isAuthenticated) {

            // Fetch company profile
            getCompanyProfile(symbol)
                .then((data) => {
                    let currentSecurity = {};
                    if (data && data.securities) {
                        currentSecurity = data.securities.find(
                            (security) => security.symbol.toLowerCase() === symbol.toLowerCase()
                        );
                    }

                    // Handle invalid phone numbers
                    if (data.phone && data.phone.length < 7) data.phone = null;

                    // Handle index statuses sorting
                    if (data.indexStatuses && data.indexStatuses.length > 0) {
                        const indexOrder = [
                            '.OTCQX', '.OTCQXBIL', '.OTCQXDIV', '.OTCQXBK', '.OTCQXINT', '.OTCQXCAN', '.OTCQXMJ', '.OTCQXUS'
                        ];
                        data.indexStatuses.sort((a, b) =>
                            indexOrder.indexOf(a.indexSymbol) - indexOrder.indexOf(b.indexSymbol)
                        );
                    }

                    // Set flags for content display
                    const hasQuotes = !(data.isCaveatEmptor && (data.tierGroup === 'OO' || data.tierGroup === 'EM' ||
                        currentSecurity.tierId === 21 || currentSecurity.tierId === 22));
                    const isOTC = ['QX', 'DQ', 'PS', 'EM'].includes(data.tierGroup);
                    const isListed = !data.tierGroup;
                    const isGrey = data.tierGroup === 'OO';

                    // Set warnings
                    let warning = [];
                    if (data.tierGroup === 'OO') warning.push('grey');
                    if (currentSecurity.tierId === 21) warning.push('limited-info');
                    if (data.isBankrupt) warning.push('bankrupt');
                    if (currentSecurity.tierId === 22) warning.push('no-info');
                    if (data.isUnsolicited) warning.push('unsolicited');
                    if (data.tierGroup === 'EM') warning.push('expert-tier');
                    if (data.isCaveatEmptor && !hasQuotes) warning.push('caveat-emptor');

                    const companyProfile = {
                        ...data,
                        currentSecurity,
                        warning,
                        isOTC,
                        isListed,
                        hasQuotes,
                        isGrey
                    };

                    companyDispatch({ type: 'SET_COMPANY', payload: companyProfile });
                })
                .catch((err) => {
                    console.error(err);
                    authDispatch({ type: 'SET_INVALID_SYMBOL' });
                });

            // Fetch inside data
            getInsideData(symbol)
                .then((data) => {
                    companyDispatch({ type: 'SET_COMPANY_INSIDE', payload: data });
                })
                .catch((err) => {
                    console.error(err);
                });
        }

    }, [symbol, authState.isAuthenticated, companyDispatch, authDispatch]);
};
