import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImageWithFallback = ({ src, alt, fallbackSrc, className }) => {
    const [hasError, setHasError] = useState(false);

    const handleError = () => {
        setHasError(true);
    };

    // If there's an error, fallback to the provided fallbackSrc or just render nothing
    return !hasError ? (
        <img className={className} src={src} alt={alt} onError={handleError} />
    ) : (
        fallbackSrc ? <img className={className} src={fallbackSrc} alt={alt} /> : null
    );
};

ImageWithFallback.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    fallbackSrc: PropTypes.string,
    className: PropTypes.string,
};

export default ImageWithFallback;
