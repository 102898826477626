import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';

const Column = ({ size, children }) => (
  <View style={styles[`_${size}`]}>
    {children}
  </View>
);

const styles = StyleSheet.create({
  _25: {
    flex: 2.5,
    paddingRight: 16
  },
  _50: {
    flex: 5
  },
  _75: {
    flex: 7.5,
    paddingRight: 16
  },
  _100: {
    flex: 10
  }
});

Column.defaultProps = {
  size: '100'
};

Column.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
};

export default Column;
