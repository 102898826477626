import React from 'react';
import PropTypes from 'prop-types';
import { format } from '../utlis/locale';
import { FINANCIAL_FORM_FIELDS, TG32_QUESTIONS } from '../constants';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import { Column, Divider, Row, Label, Value, SectionHeader } from './components';
import styles from './PDF.styles';

const Template12G32 = ({ data, companyName, symbol, userEmail }) => {
  const TODAY_DATE = new Date();
  const QUESTIONS = TG32_QUESTIONS;
  const publishDate = data[FINANCIAL_FORM_FIELDS.IS_IMMEDIATE] === 'true' ? format(TODAY_DATE, TODAY_DATE, 'date') : format(data[FINANCIAL_FORM_FIELDS.PUBLISH_DATE], [FINANCIAL_FORM_FIELDS.PUBLISH_DATE], 'date');

  return (
    <Document style={styles.document}>
      <Page style={styles.page} size='A4'>
        <SectionHeader text='12g3-2b Certification' />
        <View>
          <Row>
            <Column size='50'>
              <Label text='Submitted By' />
              <Value text={userEmail} />
            </Column>
            <Column size='50'>
              <Label text='Issuer Name' />
              <Value text={companyName} />
            </Column>
          </Row>
          <Row>
            <Column size='50'>
              <Label text='Date Submitted' />
              <Value text={publishDate} />
            </Column>
            <Column size='50'>
              <Label text='Symbol' />
              <Value text={symbol} />
            </Column>
          </Row>
        </View>
        <Divider />
        <Row>
          <Column size='75'>
            <Label text='Question' />
            <Value text={QUESTIONS.MAINTAIN_LISTING} />
          </Column>
          <Column size='25'>
            <Label text='Answer' />
            <Value text={format(data[FINANCIAL_FORM_FIELDS.MAINTAIN_LISTING], null, 'bool')} />
          </Column>
        </Row>
        <Row>
          <Column size='75'>
            <Label text='Question' />
            <Value text={QUESTIONS.FOREIGN_PRIV_ISSUER.text} />
            <Value text={QUESTIONS.FOREIGN_PRIV_ISSUER.subText} />
            <View style={styles.list}>
              {QUESTIONS.FOREIGN_PRIV_ISSUER.unorderedList.map((listItem, index) => <>
                <Text key={`prev-iss-${index}`} style={styles.listItem}>
                  - {listItem.text}
                </Text>
                {listItem.unorderedList && <View style={styles.list}>
                  {listItem.unorderedList.map(ulist => <Text key={`prev-iss-${ulist.text}`} style={styles.listItem}>
                    - {ulist.textPdf || ulist.text}
                  </Text>)}
                </View>}
              </>)
              }
            </View>
          </Column>
          <Column size='25'>
            <Label text='Answer' />
            <Value text={format(data[FINANCIAL_FORM_FIELDS.FOREIGN_PRIV_ISSUER], null, 'bool')} />
          </Column>
        </Row>
        <Row>
          <Column size='75'>
            <Label text='Question' />
            <Value text={QUESTIONS.PUBLISH_EX_ACT.text} />
            <View style={styles.list}>
              {QUESTIONS.PUBLISH_EX_ACT.unorderedList.map((ulist, index) => <>
                <Text key={`prev-iss-${index}`} style={styles.listItem}>
                  - {ulist.textPdf || ulist.text}
                </Text>
              </>)
              }
            </View>
          </Column>
          <Column size='25'>
            <Label text='Answer' />
            <Value text={format(data[FINANCIAL_FORM_FIELDS.PUBLISH_EX_ACT], null, 'bool')} />
          </Column>
        </Row>
        <Row>
          <Column size='100'>
            <Label text='Signature' />
            <Value text={data[FINANCIAL_FORM_FIELDS.TG32_CERTIFY]} />
          </Column>
        </Row>
      </Page>
    </Document>
  );
};

Template12G32.propTypes = {
  companyName: PropTypes.string,
  data: PropTypes.object,
  symbol: PropTypes.string,
  userEmail: PropTypes.string
};

export default Template12G32;
