import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '@otcmarketsgroup/otcm-ui-lib';

const ConfirmationSection = ({ type, companyName, body, children }) => {
  return (
    <Box
      flex='1 1'
      alignContent='center'
      textAlign='center'>
      <Typography gutterBottom sx={{ px: 10 }}>
        {type} posted for {companyName}
      </Typography>
      {body && <Typography variant='body1' gutterBottom sx={{ px: 30 }}>
        {body}
      </Typography>}
      {children && children}
    </Box>
  );
};

ConfirmationSection.propTypes = {
  type: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  body: PropTypes.string,
  children: PropTypes.node
};

export default ConfirmationSection;